<template>
  <div class="contact-us">
    <div class="contact-us-card-container">
      <img
        class="contact-us-card"
        :src="src"
        v-for="src in imgages"
        :key="src"
      />
    </div>
    <contact-us />
    <action-sheet :menu="enumerableRoutes" />
  </div>
</template>

<script>
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
export default {
  mixins: [enumerableRoutesMixin],
  data: () => ({
    imgages: [
      require("@/image/contact-us/img_cooperate_us@3x.png"),
      require("@/image/contact-us/img_retail_cooperate_us@3x.png"),
      require("@/image/contact-us/img_brand_cooperate_us@3x.png"),
      require("@/image/contact-us/img_agent_cooperate_us@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.contact-us {
  background-color: $bgColorfff;
  .contact-us-card-container {
    padding: 70px 30px 0 30px;
    box-sizing: border-box;
    .contact-us-card {
      width: 100%;
      height: 360px;
      margin-bottom: 40px;
    }
  }
}
</style>
